import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useEffect, useState } from 'react';
import auth from '@motimate/auth';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOrganizationQuery } from '@/lib/hooks/useOrganization';
import { getClickableProps } from '@ui/utils/a11y';
import AuthLayout, { AuthForm } from '@/auth/Layout';
import IconClose from '@/icons/close.svg';
import UIButton from '@/components/ui/button';
import UIDivider from '@/components/ui/divider';
import AppDocumentTitle from '@/components/ui/document-title';
import UIIcon from '@/components/ui/icon';
import UIInput from '@/components/ui/input';
import UISpinner from '@/components/ui/spinner';
import UIText from '@/components/ui/text';
import { UIH1 } from '@/components/ui/typo';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var IDENTIFIER_REGEXP = /^[0-9a-z\s\-_]+$/;
var normalizeIdentifier = function normalizeIdentifier(identifier) {
  return identifier.trim().toLowerCase();
};
var _ref2 = true ? {
  name: "18b9jja",
  styles: "font-size:1.125rem;line-height:1.75rem"
} : {
  name: "17a7vf3-LoggedInOrganizations",
  styles: "font-size:1.125rem;line-height:1.75rem;label:LoggedInOrganizations;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = true ? {
  name: "1c7rgvj",
  styles: "display:flex;flex-wrap:wrap;justify-content:center;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));}"
} : {
  name: "s64x20-LoggedInOrganizations",
  styles: "display:flex;flex-wrap:wrap;justify-content:center;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));};label:LoggedInOrganizations;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = true ? {
  name: "4iblxj",
  styles: "margin-bottom:0.5rem;display:flex;align-items:center;justify-content:flex-start;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.75rem * var(--tw-space-x-reverse));margin-left:calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));}overflow:hidden;text-overflow:ellipsis;white-space:nowrap;border-radius:0.75rem;border-width:1px;--tw-border-opacity:1;border-color:rgb(229 231 235 / var(--tw-border-opacity));padding:0.5rem;text-align:left;font-size:1rem;line-height:1.25rem;font-weight:600;--tw-text-opacity:1;color:rgb(55 65 81 / var(--tw-text-opacity));transition-property:color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:200ms;:hover{--tw-bg-opacity:1;background-color:rgb(249 250 251 / var(--tw-bg-opacity));--tw-text-opacity:1;color:rgb(107 114 128 / var(--tw-text-opacity));}"
} : {
  name: "ridq9e-LoggedInOrganizations",
  styles: "margin-bottom:0.5rem;display:flex;align-items:center;justify-content:flex-start;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.75rem * var(--tw-space-x-reverse));margin-left:calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));}overflow:hidden;text-overflow:ellipsis;white-space:nowrap;border-radius:0.75rem;border-width:1px;--tw-border-opacity:1;border-color:rgb(229 231 235 / var(--tw-border-opacity));padding:0.5rem;text-align:left;font-size:1rem;line-height:1.25rem;font-weight:600;--tw-text-opacity:1;color:rgb(55 65 81 / var(--tw-text-opacity));transition-property:color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:200ms;:hover{--tw-bg-opacity:1;background-color:rgb(249 250 251 / var(--tw-bg-opacity));--tw-text-opacity:1;color:rgb(107 114 128 / var(--tw-text-opacity));};label:LoggedInOrganizations;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = true ? {
  name: "z4cqn2",
  styles: "height:2rem;width:2rem;overflow:hidden;border-radius:0.5rem;border-width:1px;--tw-border-opacity:1;border-color:rgb(209 213 219 / var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity))"
} : {
  name: "1ex01r9-LoggedInOrganizations",
  styles: "height:2rem;width:2rem;overflow:hidden;border-radius:0.5rem;border-width:1px;--tw-border-opacity:1;border-color:rgb(209 213 219 / var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));label:LoggedInOrganizations;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = true ? {
  name: "hjxejd",
  styles: "max-width:100%;max-height:inherit"
} : {
  name: "u98ji7-LoggedInOrganizations",
  styles: "max-width:100%;max-height:inherit;label:LoggedInOrganizations;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = true ? {
  name: "992ns",
  styles: "border-radius:0.75rem;padding:0.5rem;:hover{--tw-bg-opacity:1;background-color:rgb(229 231 235 / var(--tw-bg-opacity));}"
} : {
  name: "1c894k1-LoggedInOrganizations",
  styles: "border-radius:0.75rem;padding:0.5rem;:hover{--tw-bg-opacity:1;background-color:rgb(229 231 235 / var(--tw-bg-opacity));};label:LoggedInOrganizations;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = true ? {
  name: "1l6dy7w",
  styles: "margin-top:0px !important;margin-bottom:0px !important"
} : {
  name: "12bf810-LoggedInOrganizations",
  styles: "margin-top:0px !important;margin-bottom:0px !important;label:LoggedInOrganizations;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var LoggedInOrganizations = function LoggedInOrganizations(_ref) {
  var organizations = _ref.organizations,
    onOrganizationSelect = _ref.onOrganizationSelect;
  return _jsxs(_Fragment, {
    children: [_jsx(UIDivider, {}), _jsx(UIH1, {
      css: _ref2,
      children: _jsx(FormattedMessage, {
        id: "organizationSelector.motiSpaces.title"
      })
    }), _jsx("div", {
      css: _ref3,
      children: organizations.map(function (organization) {
        return _jsxs("a", {
          href: "/".concat(organization.identifierName, "/signin"),
          css: _ref4,
          children: [_jsx("div", {
            css: _ref5,
            children: organization.logo.url && _jsx("img", {
              css: _ref6,
              src: organization.logo.url,
              alt: organization.name
            })
          }), _jsx("span", {
            children: organization.name
          }), _jsx("div", _objectSpread(_objectSpread({
            css: _ref7
          }, getClickableProps(onOrganizationSelect(organization.identifierName), 'button')), {}, {
            children: _jsx(UIIcon, {
              as: IconClose,
              size: 8,
              css: _ref8
            })
          }))]
        }, organization.identifierName);
      })
    })]
  });
};
var _ref12 = true ? {
  name: "13j9hyx",
  styles: "text-decoration-line:none"
} : {
  name: "47phb2-u",
  styles: "text-decoration-line:none;label:u;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var SigninOrganizationPage = function SigninOrganizationPage() {
  var _error$response;
  var router = useRouter();
  var _useIntl = useIntl(),
    formatMessage = _useIntl.formatMessage;
  var _useState = useState(''),
    organizationIdentifier = _useState[0],
    setOrganizationIdentifier = _useState[1];
  var _useState2 = useState([]),
    organizations = _useState2[0],
    setOrganizations = _useState2[1];
  var _useState3 = useState(null),
    validationError = _useState3[0],
    setValidationError = _useState3[1];
  var _useOrganizationQuery = useOrganizationQuery({
      redux: true
    }, {
      enabled: false
    }, normalizeIdentifier(organizationIdentifier)),
    refetch = _useOrganizationQuery.refetch,
    isFetching = _useOrganizationQuery.isFetching,
    error = _useOrganizationQuery.error;
  var disabled = Boolean(organizationIdentifier.length === 0 || validationError);
  useEffect(function () {
    var lastOrganization = auth.getLastActiveOrganization();
    var users = auth.getListOfUsers();
    if (lastOrganization && users.includes(lastOrganization)) {
      router.push("/".concat(lastOrganization, "/signin"));
      return;
    }
    var nextOrganizations = [];
    Object.entries(auth.getConfigs()).forEach(function (_ref9) {
      var _ref10 = _slicedToArray(_ref9, 2),
        identifierName = _ref10[0],
        config = _ref10[1];
      if (!config) return;
      var logo = config.logo,
        name = config.name;
      nextOrganizations.push({
        identifierName: identifierName,
        logo: logo !== null && logo !== void 0 ? logo : {
          url: null
        },
        name: name
      });
    });
    setOrganizations(nextOrganizations);
  }, []);
  useEffect(function () {
    if (organizationIdentifier.length === 0 || IDENTIFIER_REGEXP.test(normalizeIdentifier(organizationIdentifier))) {
      setValidationError(null);
      return;
    }
    setValidationError(formatMessage({
      id: 'organizationSelector.input.valiationError'
    }, {
      code: function code(chunks) {
        return _jsx("code", {
          children: chunks
        });
      }
    }));
  }, [organizationIdentifier]);
  var handleFormSubmit = function handleFormSubmit(event) {
    event.preventDefault();
    if (validationError) {
      return;
    }
    if (organizationIdentifier) {
      refetch().then(function (_ref11) {
        var data = _ref11.data;
        if (!data) return;
        var identifierName = data.identifier_name;
        router.push("/".concat(identifierName, "/signin"));
      });
    }
  };
  var handleIconClick = function handleIconClick(identifierName) {
    return function (event) {
      event.stopPropagation();
      event.preventDefault();
      if (!identifierName) return;
      setOrganizations(organizations.filter(function (organization) {
        return organization.identifierName !== identifierName;
      }));
      auth.logoutOrganization(identifierName);
    };
  };
  var handleOrganizationIdentifierChange = function handleOrganizationIdentifierChange(event) {
    var value = event.target.value.toUpperCase();
    setOrganizationIdentifier(value);
  };
  var inputPlaceholder = formatMessage({
    id: 'organizationSelector.input.placeholder'
  });
  return _jsxs(AuthLayout, {
    children: [_jsx(AppDocumentTitle, {
      descriptors: ['titleSignIn']
    }), _jsxs(AuthForm, {
      "data-testid": "page-auth-organization",
      onSubmit: handleFormSubmit,
      children: [_jsx(UIH1, {
        children: _jsx(FormattedMessage, {
          id: "organizationSelector.title"
        })
      }), _jsx(UIDivider, {}), _jsx(UIInput, {
        autoComplete: "off",
        "aria-label": inputPlaceholder,
        placeholder: inputPlaceholder,
        type: "text",
        variant: "white",
        value: organizationIdentifier,
        onChange: handleOrganizationIdentifierChange
      }), Boolean(validationError) && _jsx(UIText, {
        variant: "danger",
        children: validationError
      }), Boolean(error) && _jsx(UIText, {
        variant: "danger",
        children: error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 || (_error$response = _error$response.data) === null || _error$response === void 0 ? void 0 : _error$response.error.message
      }), _jsxs(UIButton, {
        disabled: disabled,
        size: "semiXlarge",
        textTransform: "uppercase",
        variant: "primary",
        "data-testid": "page-auth-organization-submit",
        children: [_jsx(FormattedMessage, {
          id: "loginForm.submitButton",
          tagName: "span"
        }), isFetching && _jsx(UISpinner, {
          size: "large",
          variant: "white"
        })]
      }), _jsx(FormattedMessage, {
        values: {
          u: function u(chunks) {
            return _jsx("a", {
              href: "https://www.motimateapp.com/privacy-policy/",
              rel: "noopener noreferrer",
              target: "_blank",
              css: _ref12,
              children: _jsx(UIText, {
                weight: "bold",
                variant: "primary",
                children: chunks
              })
            });
          }
        },
        id: "organizationSelector.privacyPolicy",
        tagName: "span"
      }), Boolean(organizations.length) && _jsx(LoggedInOrganizations, {
        organizations: organizations,
        onOrganizationSelect: handleIconClick
      })]
    })]
  });
};
export default SigninOrganizationPage;